import { Workbook } from 'exceljs';
import { noop, sortBy, sumBy } from 'lodash-es';
import {
  DashboardDiscount,
  DashboardForecast,
  DashboardInventoryStats,
  DashboardOffers,
  DashboardPartnerAndContracts,
  DashboardRevenueDetails,
  Inventory,
  useDashboardDiscountQuery,
  useDashboardForecastQuery,
  useDashboardInventoryQuery,
  useDashboardKeyInventoryQuery,
  useDashboardOffersQuery,
  useDashboardPartnerAndContractsQuery,
  useDashboardRevenueDetailsQuery,
  useSeasonQuery,
} from 'src/api';
import { useLeagueNames } from 'src/hooks/useLeagues';
import { useLocales } from 'src/hooks/useLocales';
import { Translate } from 'src/locales/locale.types';
import {
  addExportMetaDataLine,
  addWorksheet,
  applyNumFormat,
  autoSizeColumn,
  downloadSheet,
  ExportColumn,
  formatColumns,
  formatHeading,
  formatText,
  NumFormat,
  setUpWorkbook,
  toPercent,
} from 'src/utils/excel-export';
import { fDate } from 'src/utils/formatTime';

export function useExportDashboardToExcel(seasonId: string, leagueIds: string[]) {
  const { translate } = useLocales();
  // const variables = { seasonId, leagueId };
  const variables = { seasonId, leagueIds };
  const skip = !seasonId || !leagueIds?.length;
  const season = useSeasonQuery({ variables: { id: seasonId }, skip }).data?.season;
  const leagueNames = useLeagueNames(leagueIds);
  const dashboardForecast = useDashboardForecastQuery({ variables, skip }).data?.dashboardForecast;
  const dashboardInventory = useDashboardInventoryQuery({ variables, skip })?.data?.dashboardInventory;
  const dashboardDiscount = useDashboardDiscountQuery({ variables, skip }).data?.dashboardDiscount;
  const dashboardRevenueDetails = useDashboardRevenueDetailsQuery({ variables, skip }).data
    ?.dashboardRevenueDetails;
  const dashboardPartnerAndContracts = useDashboardPartnerAndContractsQuery({ variables, skip }).data
    ?.dashboardPartnerAndContracts;
  const dashboardOffers = useDashboardOffersQuery({ variables, skip }).data?.dashboardOffers;
  const allInventory = useDashboardKeyInventoryQuery({ variables, skip }).data?.allInventory;

  if (
    dashboardForecast &&
    dashboardInventory &&
    dashboardDiscount &&
    dashboardRevenueDetails &&
    dashboardPartnerAndContracts &&
    dashboardOffers &&
    allInventory
  ) {
    const filterString = [leagueNames, season?.name].filter(Boolean).join(', ');

    return () =>
      exportDashboardToExcel(
        filterString,
        translate,
        dashboardForecast,
        dashboardInventory,
        dashboardDiscount,
        dashboardRevenueDetails,
        dashboardPartnerAndContracts,
        dashboardOffers,
        sortBy(allInventory.inventory as Inventory[], 'keyRightOrder'),
      );
  }

  return noop;
}

async function exportDashboardToExcel(
  filterString: string,
  translate: Translate,
  dashboardForecast: DashboardForecast,
  dashboardInventory: DashboardInventoryStats,
  dashboardDiscount: DashboardDiscount,
  dashboardRevenueDetails: DashboardRevenueDetails,
  dashboardPartnerAndContracts: DashboardPartnerAndContracts,
  dashboardOffers: DashboardOffers,
  allInventory: Inventory[],
): Promise<void> {
  const workbook = await setUpWorkbook();

  // Add worksheets
  setupOverview(
    workbook,
    filterString,
    dashboardForecast,
    dashboardInventory,
    dashboardDiscount,
    dashboardRevenueDetails,
    dashboardPartnerAndContracts,
  );

  setupOffers(workbook, filterString, dashboardOffers);

  setupKeyRights(workbook, filterString, allInventory, translate);

  // Start download
  const today = new Date();
  const filename = `Dashboard ${fDate(today)} ${filterString}`.trim();
  downloadSheet(workbook, filename);
}

// OVERVIEW -----------------------------------------------------------

function setupOverview(
  workbook: Workbook,
  filterString: string,
  dashboardForecast: DashboardForecast,
  dashboardInventory: DashboardInventoryStats,
  dashboardDiscount: DashboardDiscount,
  dashboardRevenueDetails: DashboardRevenueDetails,
  dashboardPartnerAndContracts: DashboardPartnerAndContracts,
) {
  const worksheet = addWorksheet(workbook, 'Übersicht');

  function addHeader(values: string[]) {
    const groupHeaderRow = worksheet.addRow(values);
    formatHeading(groupHeaderRow);
    groupHeaderRow.getCell(2).font = { size: 16 };
    groupHeaderRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'right' };
    groupHeaderRow.getCell(3).font = { size: 16 };
    groupHeaderRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'right' };
  }

  function addValues(rows: any[][], numFormat: NumFormat[]) {
    rows.map((rowValues) => {
      const row = worksheet.addRow(rowValues);
      formatText(row);
      applyNumFormat(row.getCell(2), numFormat[0]);
      applyNumFormat(row.getCell(3), numFormat[1]);
    });
    formatText(worksheet.addRow(['']));
  }

  const filterRowGenerator = addExportMetaDataLine(worksheet, filterString);

  // ------------------------------------------------------------------

  addHeader(['Erlös und Forecast', '', 'Differenz zu Plan']);
  addValues(
    [
      ['Plan', dashboardForecast.planned, 0],
      ['Ist', dashboardForecast.actual, dashboardForecast.actualToPlan],
      ['Forecast', dashboardForecast.forecast, dashboardForecast.forecastToPlan],
    ],
    ['price', 'price'],
  );

  // ------------------------------------------------------------------

  const revenue = sumBy(dashboardInventory.topCategoryRevenues, 'value');

  addHeader(['Erlös Oberkategorien', 'absolut', 'relativ']);
  addValues(
    dashboardInventory.topCategoryRevenues.map((entry) => [
      entry.label,
      entry.value,
      (entry.value / revenue) * 100,
    ]),
    ['price', 'percent'],
  );

  // ------------------------------------------------------------------

  addHeader(['Rabatte', '', '']);
  addValues([['Durchschnittsrabatt', '', toPercent(dashboardDiscount.average)]], ['percent', 'percent']);

  // ------------------------------------------------------------------

  const barter = dashboardRevenueDetails.totalBarter;
  const cash = dashboardRevenueDetails.totalPrice - barter;

  addHeader(['Erlöse', 'absolut', 'relativ']);
  addValues(
    [
      ['Cash', cash, (cash / dashboardRevenueDetails.totalPrice) * 100],
      ['Barter', barter, (barter / dashboardRevenueDetails.totalPrice) * 100],
    ],
    ['price', 'percent'],
  );

  // ------------------------------------------------------------------

  addHeader(['Erlöse', 'absolut', 'relativ']);
  addValues(
    [
      [
        'Verträge',
        dashboardRevenueDetails.contracts,
        toPercent((dashboardRevenueDetails.contracts / dashboardForecast.actual) * 100),
      ],
      [
        'Prämien',
        dashboardRevenueDetails.bonus,
        toPercent((dashboardRevenueDetails.bonus / dashboardForecast.actual) * 100),
      ],
    ],
    ['price', 'percent'],
  );

  // ------------------------------------------------------------------

  addHeader(['Inventar', '', '']);
  addValues(
    [
      ['Verkauft', dashboardInventory.stats.soldValue],
      ['Verfügbar', dashboardInventory.stats.availableValue],
      ['Geblockt', dashboardInventory.stats.blockedValue],
      ['Auslastung', '', toPercent(dashboardInventory.stats.capacityUtilization)],
    ],
    ['price', 'percent'],
  );

  // ------------------------------------------------------------------

  const { partner } = dashboardPartnerAndContracts;

  addHeader(['Partner', 'absolut', 'relativ']);
  addValues(
    [
      ['Gesamt', partner.total],
      ['Neukunden', partner.new, (partner.new / partner.total) * 100],
      ['Bestandskunden', partner.existing, (partner.existing / partner.total) * 100],
      ['Leads', partner.leads],
      ['Fixed', partner.fixed],
    ],
    ['number', 'percent'],
  );

  // ------------------------------------------------------------------

  const { contract } = dashboardPartnerAndContracts;

  addHeader(['Verträge', 'absolut', 'relativ']);
  addValues(
    [
      ['Gesamt', contract.total],
      ['Auslaufende Verträge', contract.expired, (contract.expired / contract.total) * 100],
      ['Autom. Verlängerungen (gesamt)', contract.autoRenewTotal],
      ['Autom. Verlängerungen (offen)', contract.autoRenewOpen],
      ['Kündigungen', contract.cancellations, (contract.cancellations / contract.total) * 100],
    ],
    ['integer', 'percent'],
  );

  // ------------------------------------------------------------------

  worksheet.columns.forEach((column) => {
    autoSizeColumn(column);
  });

  // ------------------------------------------------------------------

  filterRowGenerator();
}

// OFFERS -------------------------------------------------------------

function setupOffers(workbook: Workbook, filterString: string, dashboardOffers: DashboardOffers) {
  const worksheet = addWorksheet(workbook, 'Angebote');
  const filterRowGenerator = addExportMetaDataLine(worksheet, filterString);

  // ------------------------------------------------------------------

  const firstHeaderRow = worksheet.addRow(['', 'Anzahl', '', '', '', '', '', 'Wert']);
  formatHeading(firstHeaderRow);

  worksheet.getCell('B2').border = { left: { style: 'thin' }, bottom: { style: 'thin' } };
  worksheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('H2').border = { left: { style: 'thin' }, bottom: { style: 'thin' } };
  worksheet.getCell('H2').alignment = { vertical: 'middle', horizontal: 'center' };

  // ------------------------------------------------------------------

  const secondHeaderRow = worksheet.addRow([
    'Monat',
    'abgesagt',
    '5 %',
    '25 %',
    '5 %',
    '75 %',
    'abgeschlossen',
    'abgesagt',
    '5 %',
    '25 %',
    '5 %',
    '75 %',
    'abgeschlossen',
  ]);
  formatHeading(secondHeaderRow);

  secondHeaderRow.alignment = { vertical: 'middle', horizontal: 'right' };
  secondHeaderRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  secondHeaderRow.getCell(2).border = { left: { style: 'thin' } };
  secondHeaderRow.getCell(8).border = { left: { style: 'thin' } };

  // ------------------------------------------------------------------

  dashboardOffers.categories.forEach((month) => {
    const row = worksheet.addRow([month]);
    formatText(row);
  });

  dashboardOffers.amountSeries.forEach((series, columnIndex) => {
    applyNumFormat(worksheet.getColumn(columnIndex + 2), 'integer');

    series.data.forEach((value, rowIndex) => {
      const cell = worksheet.getCell(rowIndex + 4, columnIndex + 2);
      cell.value = value;
      if (columnIndex === 0) cell.border = { left: { style: 'thin' } };
    });
  });

  dashboardOffers.valueSeries.forEach((series, columnIndex) => {
    applyNumFormat(worksheet.getColumn(columnIndex + 8), 'price');

    series.data.forEach((value, rowIndex) => {
      const cell = worksheet.getCell(rowIndex + 4, columnIndex + 8);
      cell.value = value;
      if (columnIndex === 0) cell.border = { left: { style: 'thin' } };
    });
  });

  // ------------------------------------------------------------------

  // reformat percentages in header
  [3, 4, 5, 6, 9, 10, 11, 12].forEach((columnIndex) =>
    applyNumFormat(secondHeaderRow.getCell(columnIndex), 'percent'),
  );

  // autosize columns
  worksheet.columns.forEach((column) => {
    autoSizeColumn(column);
  });

  // merge cells in header row
  worksheet.mergeCells(2, 2, 2, 7);
  worksheet.mergeCells(2, 8, 2, 13);

  filterRowGenerator();
}

// KEY RIGHTS ---------------------------------------------------------

const KEY_RIGHTS_LAYOUT: ExportColumn<Inventory>[] = [
  { title: 'Name ', renderItem: (inventory) => inventory.name },
  { title: 'Einheit', renderItem: (inventory, translate) => translate(inventory.unit), align: 'center' },
  { title: 'Bestand', renderItem: (inventory) => inventory.stats?.total, numFormat: 'integer' },
  { title: 'Preis / Einheit', renderItem: (inventory) => inventory.listPrices[0].price, numFormat: 'price' },
  { title: 'Verkauft', renderItem: (inventory) => inventory.stats?.sold, numFormat: 'integer' },
  { title: 'Verfügbar', renderItem: (inventory) => inventory.stats?.available, numFormat: 'integer' },
  { title: 'Geblockt', renderItem: (inventory) => inventory.stats?.blocked, numFormat: 'integer' },
  { title: 'Angeboten', renderItem: (inventory) => inventory.stats?.offered, numFormat: 'integer' },
];

function setupKeyRights(
  workbook: Workbook,
  filterString: string,
  allInventory: Inventory[],
  translate: Translate,
) {
  const worksheet = addWorksheet(workbook, 'Key Rights');
  const filterRowGenerator = addExportMetaDataLine(worksheet, filterString);

  const headerRow = worksheet.addRow(KEY_RIGHTS_LAYOUT.map((column) => column.title));
  formatHeading(headerRow);

  allInventory?.forEach((inventory) => {
    const contactsRow = worksheet.addRow(
      KEY_RIGHTS_LAYOUT.map((column) => column.renderItem?.(inventory, translate) ?? ''),
    );
    formatText(contactsRow);
  });

  formatColumns(worksheet, KEY_RIGHTS_LAYOUT);

  filterRowGenerator();
}
