import { useState } from 'react';
import { Card, CardContent, CardHeader, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDashboardForecastQuery, useDashboardInventoryQuery, useMyAccountQuery } from 'src/api';
import { fCurrency } from 'src/utils/formatNumber';
import { DashboardParams } from './Dashboard';
import { RevenueChart } from './RevenueChart';
import { TopCategoryDonut } from './TopCategoryDonut';

enum Mode {
  Ist = 'Ist',
  Forecast = 'Forecast',
  Oberkategorien = 'Oberkategorien',
}

const modes = [Mode.Ist, Mode.Forecast];

const getTextColor = (value: number) => (value < 0 ? 'error.dark' : 'success.dark');

export function DashboardForecast({ seasonId, leagueIds }: DashboardParams) {
  const theme = useTheme();

  const [mode, setMode] = useState(Mode.Ist);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMode(event.target.value as Mode);
  }

  const { data } = useDashboardForecastQuery({ variables: { seasonId, leagueIds } });
  const { data: accountData } = useMyAccountQuery();
  const { data: inventoryData } = useDashboardInventoryQuery({ variables: { seasonId, leagueIds } });

  if ((accountData?.myAccount?.topCategories || []).length > 0 && modes.length == 2)
    modes.push(Mode.Oberkategorien);

  if (!data) return null;

  const { planned, actual, actualToPlan, forecast, forecastToPlan, chartSeries } = data.dashboardForecast;

  return (
    <Card
      variant="outlined"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <CardHeader
        title="Erlös &amp; Forecast"
        action={
          <TextField size="small" select label="" value={mode} onChange={handleChange}>
            {modes.map((x, i) => (
              <MenuItem key={i} value={x}>
                {x}
              </MenuItem>
            ))}
          </TextField>
        }
      />
      {(mode == Mode.Ist || mode == Mode.Forecast) && (
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>
              <Typography variant="h6">Plan</Typography>
              <Typography variant="subtitle2">{fCurrency(planned || 0)}</Typography>
            </div>
            {mode == Mode.Ist && (
              <>
                <div>
                  <Typography variant="h6">Ist</Typography>
                  <Typography variant="subtitle2">{fCurrency(actual || 0)}</Typography>
                </div>
                <div>
                  <Typography variant="h6">zu Plan</Typography>
                  <Typography variant="subtitle2" color={getTextColor(actualToPlan)}>
                    {actualToPlan > 0 && '+'}
                    {fCurrency(actualToPlan || 0)}
                  </Typography>
                </div>
              </>
            )}
            {mode == Mode.Forecast && (
              <>
                <div>
                  <Typography variant="h6">Forecast</Typography>
                  <Typography variant="subtitle2">{fCurrency(forecast || 0)}</Typography>
                </div>
                <div>
                  <Typography variant="h6">zu Plan</Typography>
                  <Typography variant="subtitle2" color={getTextColor(forecastToPlan)}>
                    {forecastToPlan > 0 && '+'}
                    {fCurrency(forecastToPlan || 0)}
                  </Typography>
                </div>
              </>
            )}
          </Stack>
          <div style={{ paddingTop: 50 }}>
            <RevenueChart
              chartData={chartSeries}
              chartColors={[
                theme.palette.primary.dark,
                theme.palette.primary.main,
                theme.palette.primary.main,
              ]}
            />
          </div>
        </CardContent>
      )}

      {mode == Mode.Oberkategorien && (
        <TopCategoryDonut
          chartColors={[theme.palette.primary.darker, theme.palette.primary.main, theme.palette.primary.dark]}
          chartData={inventoryData?.dashboardInventory.topCategoryRevenues || []}
        />
      )}
    </Card>
  );
}
