import { useState } from 'react';
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useDashboardOffersQuery } from 'src/api';
import { DashboardParams } from './Dashboard';
import { OfferStackedBar } from './OfferStackedBar';

const modes = ['#', '€'];

export function DashboardOffer({ seasonId, leagueIds }: DashboardParams) {
  const [mode, setMode] = useState(modes[0]);

  const { data } = useDashboardOffersQuery({ variables: { seasonId, leagueIds } });

  if (!data) return null;

  return (
    <Card variant="outlined">
      <CardHeader
        title="Angebote"
        action={
          <ToggleButtonGroup exclusive value={mode} onChange={(_event, value) => setMode(value)} size="small">
            {modes.map((x) => (
              <ToggleButton key={x} value={x} sx={{ px: 2 }}>
                {x}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        }
      />
      <CardContent>
        <OfferStackedBar
          categories={data.dashboardOffers.categories}
          series={mode == modes[0] ? data.dashboardOffers.amountSeries : data.dashboardOffers.valueSeries}
          chartColors={['#788991', '#fda27e', '#fecb4d', '#ffeb6d', '#dcec5d', '#77ea7e']}
          mode={mode}
        />
      </CardContent>
    </Card>
  );
}
